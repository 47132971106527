@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-solid-straight/css/uicons-solid-straight.css');
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-solid-rounded/css/uicons-solid-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-thin-straight/css/uicons-thin-straight.css');
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-bold-rounded/css/uicons-bold-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-bold-straight/css/uicons-bold-straight.css');

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@font-face {
  font-family: "berkeleyMono";
  src: url("../public/fonts/BerkeleyMono-Regular.ttf");
}

body {
  margin: 0;
  font-family: 'berkeleyMono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-inter {
  font-family: 'Inter', serif;
}

/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.bg-gradient {
  background: rgb(2,2,89);
  background: linear-gradient(90deg, rgba(2,2,89,1) 0%, rgba(0,0,255,1) 100%);
}

.bg-pattern {
  background-image: url('./assets/images/bg-pattern\(dark-mode\).svg');
  background-size: cover;
  background-clip: initial;
  background-blend-mode: saturation;
}

.bg-pattern-white {
  background-image:linear-gradient(transparent 10%, rgb(249,250,251) 100%), url('./assets/images/bg-pattern\(dark-mode\).svg');
  background-size: cover;
  background-clip: initial;
  background-blend-mode: saturation;
}


.carousel-wrapper {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%; /* Set width to whatever is required */
}

.carousel-images {
  display: flex;
}

.carousel-image {
  width: auto;
  /* height: 100%; */
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}



html{
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000000 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="text"] { 
  caret-color: #B39659; 
} 

.ping {
  -webkit-animation: ping 0.8s ease-in-out infinite both;
  animation: ping 0.8s ease-in-out infinite both;
}

@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2);
    opacity: 0;
  }
}